import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Work extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  render() {
    if (!this.props.data) return null

    const pride = this.props.data.pride.map(function (pride) {
      return (
        <div key={pride.team} className="prideContainer">
          <h3>{pride.team}</h3>
          <p className="info">
            {pride.desc} <span>&bull;</span>
            <em className="date">{pride.date}</em>
          </p>
          <img src={pride.image} alt="pride" />
        </div>
      )
    })

    const milestones = this.props.data.milestones.map(function (milestones) {
      return (
        <ul class="milestones">
          <li class="milestones-event">
            <label class="milestones-event-icon"></label>
            <div class="milestones-event-copy">
              <div className="milestonesContainer">
                <p class="milestones-event-thumbnail">{milestones.date}</p>
                <img className="milestonesLogo" src={milestones.image} alt="milestones" />
              </div>
              <h4>{milestones.title}</h4>
              <ul>
                {milestones.desc.map((description, idx) => (
                  <li key={idx}>{description}</li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      )
    })

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor();
    //   const className = "bar-expand " + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Our Pride</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{pride}</div>
              </div>
            </div>
          </div>
        </Slide>

        <section id="milestones">
          <Slide left duration={1300}>
            <div className="row work">
              <div className="three columns header-col">
                <h1>
                  <span>Milestones</span>
                </h1>
              </div>
              <div className="nine columns main-col">{milestones}</div>

              {/* Add View All button */}
              <div className="viewallContainer">
                <a href="/timeline" target="_blank" rel="noopener noreferrer">
                  <button className="viewall-button">View All ...</button>
                </a>
              </div>
            </div>
          </Slide>
        </section>

        <section id="robocup">
          <Slide left duration={1300}>
            <div className="row skill">
              <div className="three columns header-col">
                <h1>
                  <span>RoboCup Junior</span>
                </h1>
              </div>

              <div className="nine columns main-col">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/v7CEL-KYMAM?si=eqO6D_JLVAn5iBdH"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </Slide>
        </section>

        {/* <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide> */}
      </section>
    )
  }
}

export default Work
